import { createRouter, createWebHistory } from 'vue-router';
import TheHome from './components/TheHome.vue';
/* import TheEcommerce from './components/pages/TheEcommerce.vue';
 */import TheWebsite from './components/pages/TheWebsite.vue';

const router = createRouter({
    //permite añadir un listado de las rutas por las que ha navegado el usuario
    history: createWebHistory(),
    //se registran las rutas
    routes: [
        { path: '/', component: TheHome, alias: '/' },
        // our-domain.com/teams =>TeamsList
/*         { path: '/ecommerce', component: TheEcommerce },
 */        { path: '/pagina-web', component: TheWebsite },
        { path: '/:notFound(.*)', redirect: '/' }


    ],

    //tenemos la opción de sobreescribir la clase que automáticamente vue añade (router-link-active) por la que nosotros queremos
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        /* console.log(to, from, savedPosition); */
        //savedPosition funciona cuando se utiliza el botón back del navegador
        if (savedPosition) {
            return savedPosition;
        }
        return { left: 0, top: 0 };
    }

});

export default router;
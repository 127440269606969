<template>
  <!-- Últimos trabajos -->
  <section class="o-container-fluid servicios u-py-90" id="servicios">
    <!-- <div class="u-bg-image-2"></div> -->
    <!-- <div class="o-container o-flex o-flex--justify-content"> -->
    <div class="u-special-title">
      <p>
        wwwhattt <br />
        wwwwe<br />
        do
      </p>
    </div>
    <div class="o-container u-zindex-1 u-p-1">
      <h2 class="u-text-highlight animate" data-animate="slide-right 0.5s linear forwards">
        Servicios Web
      </h2>
      <div class="u-text-center u-mb-30">
        <span class="u-special-subtitle animate">
          Solución integral para páginas web, SEO/SEM, Marketing Digital y Fotografía.
        </span>
      </div>
      <div class="row u-mt-5">
        <div class="col-12 u-mx-auto">
          <ul class="row align-items-stretch">
            <li
              v-for="service in services"
              :key="service.id"
              class="col-md-6 col-lg-4 c-card flex-row u-mb-2 animate"
              data-animate="slide-right 0.5s linear forwards"
            >
              <div
                class="u-p-1 d-flex flex-column u-m-auto hover"
                @mouseover="hover = true"
                @mouseleave="hover = false"
              >
                <!--  <div class="c-btn--cta isActive">
                  <a href="#" class="c-btn u-glass-wh">{{ service.cta }}</a>
                </div> -->
                <div class="u-text-center">
                  <img
                    loading="lazy"
                    :src="require('@/assets/images/' + service.img)"
                    class="skill-image"
                  />
                </div>
                <h3 class="c-card__title">{{ service.name }}</h3>
                <hr />
                <p class="c-card__description u-pt-1">{{ service.description }}</p>
                <div class="c-btn-primary u-text-center">Leer más</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import windowScrollPosition from '@/assets/js/scrollPosition.js';

export default {
  /* mixins: [windowScrollPosition('position')], */
  data() {
    return {
      hover: false,
      services: [
        {
          id: 1,
          name: 'Desarrollo de Sitios y Aplicaciones Web',
          description:
            'Desarrollamos páginas web para sus negocios que se adaptan a todas todo tipo de dispositivos y resoluciones de pantalla.',
          img: 'undraw_web_development_w2vv.svg',
          cta: 'Quiero una Web',
          fragment: 'desarrollo-de-sitios-y-aplicaciones-web',
        },
        {
          id: 2,
          name: 'E-Commerce',
          description:
            'La digitalización de su negocio nunca tuvo tanto protagonismo. Creamos tu tienda online para expandir tu empresa y ser visible a más clientes.',
          img: 'undraw_add_to_cart_vkjp.svg',
          cta: 'Quiero una Tienda',
          fragment: 'e-commerce',
        },
        {
          id: 3,
          name: 'Diseño UI/UX',
          description:
            'Diseñamos interfaces interactivas centradas en el usuario y creamos experiencias de usuario únicas acordes a la identidad de su empresa.',
          img: 'undraw_design_components_9vy6.svg',
          cta: 'Quiero UI/UX',
          fragment: 'e-commerce',
        },
        {
          id: 4,
          name: 'SEO / SEM',
          description:
            'Optimizamos y diseñamos su web mediante técnicas WhiteHat y SEO OnPage para que los motores de búsqueda la posicionen en las primeras páginas de resultados y lograr mayor tráfico en su web.',
          img: 'undraw_web_search_eetr.svg',
          cta: 'Quiero SEO/SEM',
          fragment: 'seo-sem',
        },
        {
          id: 5,
          name: 'Fotografía',
          description:
            'Realizamos fotografía de producto, servicios e inmobiliaria para su web. Ofrecemos packs de páginas web + fotografía con las que captaremos mayor atención de sus clientes.',
          img: 'undraw_product_photography_91i2.svg',
          cta: 'Quiero Fotos',
          fragment: 'fotografia',
        },
        {
          id: 6,
          name: 'Marketing Digital',
          description:
            'Planificamos y Desarrollamos tácticas del Marketing 2.0, el cuál se centra en el usuario, con el objetivo de aportar valor y captar su atención.  ',
          img: 'undraw_marketing_re_7f1g.svg',
          cta: 'Marketing',
          fragment: 'marketing-digital',
        },
      ],
    };
  },
  /*   mounted() {
    const container = document.querySelector('.section');
    console.log(container);
    console.log(container.scrollTop);
    console.log(container.scrollLeft);
  }, */
  methods: {
    handleScroll(evt, el) {
      // const anim = document.querySelector('.animate');
      console.log(evt);
      if (window.scrollY > 50) {
        el.setAttribute('style', 'opacity: 1; transform: translate3d(0, -10px, 0)');
      }
      return window.scrollY > 100;
    },
    scrolling() {
      console.log('fired');
      const container = document.querySelector('.section');
      console.log(container);
      console.log(container.scrollTop);
      console.log(container.scrollLeft);
    },
    handleDebouncedScroll() {
      console.log('fired');
    },
  },
  mounted() {
    scroll;
  },

  // unmounted() {
  //   // I switched the example from `destroyed` to `beforeDestroy`
  //   // to exercise your mind a bit. This lifecycle method works too.
  //   window.removeEventListener('scroll', this.handleDebouncedScroll);
  // },
};
</script>

<style scoped lang="scss">
.servicios {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: white;
  opacity: 1;
  transition: all 1s ease-in-out;

  /*  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.3)); */
  h2 {
    margin-top: 100px;
    letter-spacing: 4px;
    font-size: 60px;
    z-index: 2;
    text-transform: uppercase;
    // box-shadow: inset 0 -0.285em white;
    // border-bottom: 0.3em solid rgba(100, 225, 55, 0.7);
  }
}

@media (min-width: 768px) {
  .servicios {
    h2 {
      margin-top: 100px;
      letter-spacing: 4px;
      font-size: 100px;
    }
  }
}
/* .u-bg-image-2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url('@/assets/images/ux-store-lg.jpg');
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
} */
.isActive {
  /* display: none; */
  opacity: 0;
}
.hover:hover .isActive {
  opacity: 1;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover {
  transition: all 900ms cubic-bezier(1, -0.195, 0, 1.33);
  transition-timing-function: cubic-bezier(1, -0.195, 0, 1.33);
}

.hover:hover .c-btn:before {
  width: 85%;
  height: 85%;
}

.display-position {
  position: fixed;
  top: 0;
  left: 0;
}

.animate {
  opacity: 0;
}

.u-special-title {
  opacity: 0.1;
}

.active {
  opacity: 1;
}

.skill-image {
  max-width: 60%;
  height: 150px;
}
</style>
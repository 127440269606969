<template>
  <!-- <the-navigation></the-navigation> -->
  <the-navigation-3></the-navigation-3>
  <router-view></router-view>
  <!-- <HelloWorld></HelloWorld> -->
  <the-footer></the-footer>
  <div class="c-btn--cta">
    <a href="#contacto" class="c-btn u-glass">Contacto</a>
  </div>
</template>

<script>
/* import TheNavigation2 from './components/nav/TheNavigation2.vue';
 */ import TheNavigation3 from './components/nav/TheNavigation3.vue';
/* import HelloWorld from './components/HelloWorld.vue'; */
/* import TheNavigation from './components/nav/TheNavigation.vue'; */
import TheFooter from './components/TheFooter.vue';

export default {
  // directives: {
  //   scroll: {
  //     // Definición de directiva
  //     inserted: function (el, binding) {
  //       let f = function (evt) {
  //         if (binding.value(evt, el)) {
  //           window.removeEventListener('scroll', f);
  //         }
  //       };
  //       window.addEventListener('scroll', f);
  //     },
  //   },
  // },
  name: 'App',
  components: {
    /* HelloWorld, */
    TheNavigation3,
    TheFooter,
  },
};
</script>

<style lang="scss">
</style>

import { createApp } from 'vue'
import './assets/styles/main.scss'
import App from './App.vue'
import router from './router.js'


//Vue.use(vuescroll, {throttle: 600})


const app = createApp(App);
/* app.directive('scroll', (el, binding) => {
    let f = function (evt) {
        if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
        }
    }
    console.log(binding.value.start);
    window.addEventListener('scroll', f)
}
); */
//conectar las rutas con el built-in method (use()) para  third party packages
app.use(router);
app.mount('#app');


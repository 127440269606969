export default function mobMenu() {

  const openMobMenu = document.querySelector('.c-hamburger-menu');
  const menu = document.querySelector('.menu');

  openMobMenu.addEventListener('click', () => {
    openMobMenu.classList.toggle('active');
    menu.classList.toggle('active');
  });

}


<template>
  <section class="contact flex-column" id="contacto">
    <div class="o-container u-zindex-1 u-p-1">
      <h2
        class="u-text-highlight animate u-color-wh"
        data-animate="slide-right 0.5s linear forwards"
      >
        Genial, si has llegado hasta aquí estamos listos para iniciar nuestro proyecto digital
        juntos
      </h2>

      <div class="u-text-center u-mb-30">
        <span class="u-special-subtitle u-color-wh animate">
          Pregunta todas las dudas y cuéntanos tu proyecto
        </span>
      </div>
    </div>
    <!-- STAR FORM CONTACT -->
    <div class="o-container justify-content-center">
      <form ref="form" @submit.prevent="sendEmail">
        <div
          class="contact-form u-glass"
          v-bind:class="{ 'contact-form-active': contactFormActive }"
        >
          <div class="contact-head"></div>
          <div class="contact-body">
            <div class="form-control">
              <div class="floating-label-input">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Full Name (Optional)"
                  v-model="contactName"
                />
                <label for="name">Nombre Completo (opcional)</label>
              </div>
            </div>
            <div class="form-control">
              <div class="floating-label-input">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail Address"
                  v-model="contactEmail"
                />
                <label for="email">Dirección email</label>
              </div>
            </div>
            <div class="form-control">
              <div class="floating-label-input">
                <textarea
                  rows="5"
                  id="message"
                  placeholder="Message for the developer"
                  v-model="contactMessage"
                  name="message"
                ></textarea>
                <label for="message">Queremos conocer tu proyecto</label>
              </div>
            </div>
            <div class="form-control text-right">
              <button class="btn btn-primary">Enviar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM CONTACT -->
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      contactName: '',
      contactEmail: '',
      contactMessage: '',
      contactFormActive: false,
    };
  },
  methods: {
    sendEmail() {
      console.log(this.$refs.form.name);
      try {
        emailjs.sendForm(
          'service_he2lxnh',
          'template_174ycub',
          this.$refs.form,
          'p0poG1y4PRkn8-kDd',
          {
            name: this.contactName,
            email: this.contactEmail,
            message: this.contactMessage,
          }
        );
        alert('Tu mensaje se ha enviado correctamente');
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.contactName = '';
      this.contactEmail = '';
      this.contactMessage = '';
    },
  },
  /* methods: {
    openContactForm: function () {
      this.contactFormActive = true;
    },
    closeContactForm: function () {
      this.contactFormActive = false;
    },
    toggleContactForm: function () {
      if (this.contactFormActive === false) {
        this.openContactForm();
      } else {
        this.closeContactForm();
      }
    },
    sendMyMessage: function () {
      var message = '';

      if (this.contactFullName != '') {
        message += 'Hello ' + this.contactFullName + '!\n\n';
      }
      if (this.contactEmail == '') {
        alert('La dirección de correo es requerida');

        return false;
      }
      if (this.contactMessage == '') {
        alert('"Tu consulta es requerida"');

        return false;
      }
      message += 'Your E-mail address you told me is: ' + this.contactEmail + '!\n\n';
      message += 'Your message for me is: "' + this.contactMessage + '"!\n';
      message += 'Thank you for your wonderful message, I am going to treasure it!\n\n';
      message += 'Thank you for you time checking this pen! I hope you like it!';
      alert(message);

      // Clear the form after sending
      this.contactFullName = '';
      this.contactEmail = '';
      this.contactMessage = '';

      this.closeContactForm();
    },
  }, */
};
</script>

<style lang="scss" scoped>
.contact {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all 1s ease-in-out;

  /*  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.3)); */
  h2 {
    margin-top: 100px;
    letter-spacing: 4px;
    font-size: 40px;
    z-index: 2;
    text-transform: uppercase;
    // box-shadow: inset 0 -0.285em white;
    // border-bottom: 0.3em solid rgba(100, 225, 55, 0.7);
  }
}

@media (min-width: 768px) {
  .contact {
    h2 {
      margin-top: 100px;
      letter-spacing: 4px;
      font-size: 100px;
    }
  }
}
</style>
<template>
  <!-- Start header -->
  <header class="navbar o-container-fluid" :class="{ 'hidden-navbar': !showNavbar }">
    <div class="row u-width-100 justify-content-between align-items-center">
      <div class="c-header--logo u-margin-x">
        <a href="#inicio"><img src="@/assets/images/logo-v4.png" height="80" /></a>
      </div>
      <!--  <a href="contact.html"><button href="contact.html" class="c-btn" style="position:absolute; bottom: 20px; left: 20px;">Obtén Presupuesto</button></a> -->
      <div class="u-pr-20">
        <a class="c-hamburger-menu u-flex u-glass">
          <span class="c-hamburger-menu--span"></span>
          <span class="c-hamburger-menu--span"></span>
        </a>
      </div>
    </div>
  </header>
  <nav class="menu u-flex">
    <ul class="u-flex u-flex--column">
      <!--      <li><a href="#inicio">Inicio</a></li>
          <li><a href="#servicios">Servicios</a></li>
          <li><a href="#trabajos">Trabajos</a></li>
          <li><a href="#sobre-mi">Sobre mí</a></li>
          <li><a href="contact.html">Contacto</a></li>  -->
      <li><a href="mailto:info@webmagnet.es" class="u-text-size-20">info@webmagnet.es</a></li>
      <li>
        <a href="mailto:marketing@webmagnet.es" class="u-text-size-20">websites@webmagnet.es</a>
      </li>
      <li>
        <a href="mailto:marketing@webmagnet.es" class="u-text-size-20">marketing@webmagnet.es</a>
      </li>
      <li>
        <a href="mailto:seo@webmagnet.es" class="u-text-size-20">seo@webmagnet.es</a>
      </li>
      <li>
        <a href="mailto:issues@webmagnet.es" class="u-text-size-20">issues@webmagnet.es</a>
      </li>

      <li>
        <a href="#" class="u-text-size-20 u-color-wh"></a>
      </li>
      <li class="u-special-list-effect">
        <p class="u-color-wh u-px-5">2024 WebMagnet.es ©Todos los derechos reservados</p>
      </li>
    </ul>
  </nav>
  <!-- End header -->
</template>

<script>
const OFFSET = 100;
export default {
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
    };
  },

  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener('scroll', this.onScroll);
    const viewportMeta = document.createElement('meta');
    viewportMeta.name = 'viewport';
    viewportMeta.content = 'width=device-width, initial-scale=1';
    document.head.appendChild(viewportMeta);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    onScroll() {
      if (window.pageYOffset < 0) {
        return;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return;
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
// Get all of the images that are marked up to fade in

export default function io() {
    const images = document.querySelectorAll('.js-lazyload-image');

    const sections = document.querySelectorAll('.animate');

    let config = {
        rootMargin: '0px',
        threshold: 0.1
    };

    let observer = new IntersectionObserver((entries) => {
        /* console.log(entries); */

        entries.forEach(entry => {
            if (entry.isIntersecting) {
                /* console.log("launching scroll observation");
                console.log(entry.intersectionRect);
                console.log("isinviewport"); */
                intersectionHandler(entry);

            }
            else {
                /* console.log("is out viewport");
                console.log("stop scroll observation"); */
            }

        });
    }, config);

    sections.forEach(section => {
        // console.log(section);
        observer.observe(section);

    });

    function intersectionHandler(entry) {
        // console.log(entry);
        const current = document.querySelector('.animate.active');
        // console.log(current);
        const next = entry.target;
        /* console.log(next); */
        //const header = next.querySelector(".section--header");

        if (current) {
            //current.classList.remove('active');
            // it's good to remove observer,
            // if you don't need it any more
            //observer.unobserve(current);
            // console.log("closing scroll observation");
        }
        if (next) {
            next.classList.add('active');
            next.style.animation = next.dataset.animate;
            // handleScroll(next);
            //document.body.style.setProperty("--color-bg", next.dataset.bgcolor);
            //observer.unobserve(next);
        }
    }

    images.forEach(image => {
        observer.observe(image);
    });
    // function handleScroll(evt, el) {
    // function handleScroll(el) {
    //     // const anim = document.querySelector('.animate');
    //     //console.log(evt);
    //     if (window.scrollY > 50) {
    //         updateCoordinates();
    //         el.setAttribute('style', 'opacity: 1; transform: translate3d(0, -10px, 0)');
    //     } else {
    //         console.log("end scroll section");
    //     }
    //     //return window.scrollY > 100;
    // }

    // function updateCoordinates() {

    //     let position = { x: 0, y: 0 };
    //     position.x = window.pageXOffset;
    //     position.y = window.pageYOffset
    //     console.log(position.x);
    //     console.log(position.y);
    //     // setInterval(() => {
    //     //     this.pos.x += (this.mouse.x - this.pos.x) * this.speed;
    //     //     this.pos.y += (this.mouse.y - this.pos.y) * this.speed;
    //     //     /* this.$refs.cursor.style.left = this.xp + 'px';
    //     //     this.$refs.cursor.style.top = this.yp + 'px'; */
    //     // }, 100);
    // }

}



/* function preloadImage(img) {
    const src = img.getAttribute('data-src');
    if (!src) { return; }
    img.src = src;
} */



// function onIntersection(entries) {
//   // Loop through the entries
//   for (var i = 0, len = entries.length; i < len; i++) {
//     // Are we in viewport?
//     if (entries[i].intersectionRatio > 0) {
//       console.log('in viewport');

//       // Stop watching and load the image
//       observer.unobserve(entries[i].target);
//       entries[i].target.classList.add('in-viewport');
//     }
//   }
// }
<template>
  <!-- Últimos Precios -->

  <section class="o-container-fluid precios u-py-90 u-price-bk u-p-1" id="precios">
    <!-- <section class="o-container-video">
      <video
        src="@/assets/images/videoFile.mp4"
        autoplay="true"
        muted="true"
        loop="true"
        poster="https://carontestudio.com/img/contacto.jpg"
      ></video>
    </section> -->
    <section class="o-container u-py-90">
      <h2
        class="u-text-highlight animate u-color-wh"
        data-animate="slide-right 0.5s linear forwards"
      >
        Precios
      </h2>
      <div class="u-text-center u-mb-30">
        <span class="u-special-subtitle animate u-color-wh">
          Planes que se ajustan a todas las necesidades.
        </span>
      </div>

      <div class="price-section row justify-content-center justify-content-sm-around u-mt-5">
        <!-- Prize 1 -->
        <div class="col-12 col-md-4 u-my-10 u-color-wh">
          <article class="price-table col-12 u-glass">
            <div class="price"><small>€</small>2000</div>
            <h2 class="title">Plan Viva Pulse</h2>
            <p class="description u-color-wh">
              Este plan está diseñado para el lanzamiento de tu marca en la Web y Social Media
            </p>
            <div class="features">
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Diseño y Desarrollo Web adaptado a tu marca (1700€ pago único)</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Estilos, tipografías, logo según tu marca.</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Diseño Responsive</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">La Aplicación Web se adapta a todos los dispositivos.</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">SEO On Page Básico</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Alta en buscadores Google y Bing.</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Integración con WhatsApp</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Integración con WhatsApp</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Formulario de contacto</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Permite a los clientes enviarte correos electrónicos.</div>
              </details>

              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">1 Idiomas</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">La tienda estará en 1 idioma (extra idioma +100€)</div>
              </details>

              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Textos legales y adecuación a RGPD</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer"></div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Hosting gratuito 1 año (despúes 90€/año)</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Depués 90€/año</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Dominio gratuito 1 año (despúes 15€/año)</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer"></div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Mantenimiento (1er año incluido , después 50€/mes)</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  Actualización core, solución de errores en código, parches de seguridad...
                  (50€/mes)
                </div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Setup Inicial Social Media (Incluido en precio: +300€/mes)</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  3 Posts Semanales en Redes Sociales (300€/mes por red social)
                </div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/error-svgrepo-com.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">SEO Avanzado (No incluido en precio: +600€/mes)</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  Keyword Research, Análisis de la competencia, Métricas Mensuales, Posts en Blog...
                  (600€/mes)
                </div>
              </details>
            </div>
          </article>
        </div>
        <!-- End Price 1 -->
        <!-- Prize 2 -->
        <div class="col-12 col-md-4 u-my-10 u-color-wh">
          <article class="price-table col-12 u-glass card-accent">
            <div class="price"><small>€</small>2600</div>
            <h2 class="title">Plan Viva Growth</h2>
            <p class="description u-color-wh">
              Este plan impulsa el crecimiento de tu marca desde el día 1 de tu presencia en línea.
            </p>
            <div class="features">
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Plan Viva Pulse</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Todas las características de VIVA PULSE.</div>
              </details>

              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">SEO AVANZADO (Incluido en el precio: despúes 600€/mes)</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  Keyword Research, Auditorías Competencia, Blog Posts, Métricas, ... Los resultados
                  se pueden observar entre el tercer y sexto mes de trabajo.
                </div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Gestión Social Media (Incluido en el precio, después 300€/mes)</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">3 publicaciones semanales. (300€/mes por cada red social)</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/error-svgrepo-com.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Campañas Email Marketing (No incluido en el precio: 200€/mes)</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  Se iniciarán una vez tengamos una base de datos de suscriptores (200€/mes)
                </div>
              </details>
            </div>
          </article>
        </div>
        <!-- End Price 2 -->
        <!-- Prize 3 -->
        <div class="col-12 col-md-4 u-my-10 u-color-wh">
          <article class="price-table col-12 u-glass">
            <div class="price"><small>€</small>3300</div>
            <h2 class="title">Plan Viva Boost</h2>
            <p class="description u-color-wh">
              Este plan escala la visibilidad y el compromiso de tu web.
            </p>
            <div class="features">
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name">Plan VIVA GROWTH</span>
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Incluye todas las características de VIVA GROWTH</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Campañas Google Ads (Incluido en precio: después 250€/campaña + Gastos
                    indirectos de "coste por click")</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">Gestionamos tus campañas de Google Ads</div>
              </details>

              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />
                  <span class="name"
                    >Campañas Redes Sociales (Incluido en precio: después 250€/campaña + Gastos
                    indirectos de "coste por click")</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>

                <div class="answer">Gestionamos tus campañas de Social Ads</div>
              </details>
              <details class="feature">
                <summary>
                  <img
                    src="@/assets/images/green-check-mark-verified-circle-16223.svg"
                    alt="Checkmark"
                    style="object-fit: contain; margin-right: 15px"
                    height="20"
                  />

                  <span class="name"
                    >Campañas Email Marketing (Incluido en el precio: 200€/mes)</span
                  >
                  <i aria-hidden="true" class="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </i>
                </summary>
                <div class="answer">
                  Se iniciarán desde el día 1 con todos los leads que se generen en durante el
                  lanzamiento ( Después: 200€/mes)
                </div>
              </details>
            </div>
          </article>
        </div>
        <!-- End Price 3 -->
      </div>
    </section>
  </section>
  <!-- Fin Precios -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.precios {
  position: relative;
  background-color: white;
}

:root {
  --c-blue-900: #081030;
  --c-blue-800: #111b40;
  --c-blue-700: #152148;
  --c-blue-500: #1d7bdb;
  --c-blue-300: #293359;
  --c-blue-200: #4e5985;
  --c-blue-100: #dfe8ff;

  --c-green-400: #4ade80;
}

.precios {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: white;
  opacity: 1;
  transition: all 1s ease-in-out;

  /*  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.3)); */
  h2 {
    margin-top: 100px;
    letter-spacing: 4px;
    font-size: 60px;
    z-index: 2;
    text-transform: uppercase;
    // box-shadow: inset 0 -0.285em white;
    // border-bottom: 0.3em solid rgba(100, 225, 55, 0.7);
  }
}

@media (min-width: 768px) {
  .precios {
    h2 {
      margin-top: 100px;
      letter-spacing: 4px;
      font-size: 100px;
    }
  }
}
.price-table {
  /* background-color: var(--c-blue-700); */
  border-radius: 16px;
  /* max-width: 375px; */
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 45px 0 rgba(#000, 0.15);
  position: relative;
  //background-image: linear-gradient(135deg, #00bfa6 40%, #00bfa6 100%);
  /*   background-image: linear-gradient(
    135deg,
    rgba(#4ade80, 0.15),
    rgba(#1d7bdb, 0.1) 20%,
    var(--c-blue-700) 40%,
    var(--c-blue-700) 100%
  ); */
  &:after {
    content: '';
    display: block;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
    z-index: -1;
    position: absolute;
    border-radius: 16px;
  }

  .price {
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    display: inline-flex;
    align-self: center;
    align-items: center;
    gap: 4px;
    position: relative;
    color: #fff;
    small {
      font-size: 1.25rem;
      font-weight: 400;
      position: absolute;
      left: -1.5ch;
    }
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    margin-top: 16px;
    color: #fff;
  }

  .description {
    font-size: 1rem;
    text-align: center;
    margin-top: 4px;
  }
}

.card-accent {
  background-image: linear-gradient(135deg, #00bfa6 40%, #00bfa6 100%);
}

.features {
  margin-top: 32px;
}

.feature {
  position: relative;
  & + & {
    margin-top: 16px;
  }
  summary {
    display: inline-flex;
    align-items: center;
    list-style: none; // Remove arrow
    &::-webkit-details-marker {
      display: none; // Remove arrow
    }
  }

  &[open] summary:after {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 50;
  }

  &[open] {
    div {
      animation: scale 0.15s ease;
    }
  }

  .question-icon {
    display: flex;
    align-items: center;
    margin-left: 6px;
    cursor: pointer;
  }

  .answer {
    padding: 12px;
    background-color: black;
    border-radius: 6px;
    position: absolute;
    top: -12px;
    z-index: 100;
    transform: translatey(-100%) translatex(-50%);
    transform-origin: bottom center;
    width: 80%;
    left: 50%;
    border: 1px solid var(--c-blue-300);
    box-shadow: 0 15px 30px 0 rgba(#000, 0.1);
  }
}

@keyframes scale {
  0% {
    transform: translatey(-100%) translatex(-50%) scale(0);
  }
  100% {
    transform: translatey(-100%) translatex(-50%) scale(1);
  }
}
</style>
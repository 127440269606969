<template>
  <section class="o-container-fluid projects u-py-90" id="porfolio">
    <!-- <div class="u-bg-image-3"></div> -->
    <div class="o-container o-flex o-flex--justify-content u-p-1">
      <div class="u-special-title u-blend-screen">
        <p>wwworks</p>
      </div>
      <h2 class="u-text-center u-text-highlight u-color-wh">Proyectos</h2>
      <div class="u-text-center u-mb-30 animate">
        <span class="u-special-subtitle u-color-wh">
          "Estos son algunos de los más apreciados trabajos de nuestros clientes"
        </span>
      </div>
      <!-- List of works -->
      <div class="row u-mt-5">
        <!-- c-col-lg4 -->
        <!-- <div class="col-12 u-py-90 prueba1" v-on:mousemove="updateCoordinates"> -->
        <div
          class="col-12 u-py-90 prueba1 u-mb-2 animate"
          data-animate="slide-right 0.5s linear forwards"
        >
          <div class="row flex-sm-column flex-lg-row align-items-lg-end">
            <div class="col-lg-8 col-12 u-overflow--h">
              <img
                loading="lazy"
                class="u-img"
                src="@/assets/images/sikara-lombok-hotel.jpg"
                alt="Página de inicio de la web Sikara Hotel"
                style="object-fit: contain"
              />
            </div>
            <div class="col-lg-4 col-12 u-overflow--h">
              <p class="u-color-wh">
                Aplicación web corporativa conectada a un channel manager (CM) que gestiona las
                reservas del hotel.
              </p>
              <p class="u-color-wh">WEB DESIGN</p>
            </div>
            <!-- <div class="cursor" ref="cursor"> -->
            <!-- <div class="cursor" ref="cursor" :style="cursorCircle"> -->
            <!-- <div class="cursor--inner"></div> -->
            <!-- </div> -->
            <!-- <div class="col-2 align-self-center">
              <span id="circle" :style="cursorCircle" class="circle" ref="circle"></span>
            </div> -->
            <div class="col-6 offset-6 u-py-30">
              <hr />
              <h3 class="u-color-wh">Sikara Lombok Hotel</h3>
            </div>
          </div>
        </div>
        <!-- end c-col-lg4 -->
        <!-- c-col-lg4 -->
        <div class="col-12 u-py-90 u-mb-2" data-animate="slide-left 0.5s linear forwards">
          <div class="row flex-sm-column flex-lg-row align-items-lg-end">
            <div class="col-lg-4 col-12 u-overflow--h order-2 order-lg-1">
              <p class="u-color-wh">
                Sitio web corportativo que presenta las actividades de una escuela de paddle surf.
              </p>
              <p class="u-color-wh">WEB DESIGN | SEO</p>
            </div>
            <div class="col-lg-8 col-12 u-overflow--h order-1 order-lg-2">
              <img
                loading="lazy"
                class="u-img"
                src="@/assets/images/paddlesurfpeniscola.jpg"
                alt="Página de inicio de la web Paddle Surf Peñíscola"
              />
            </div>
            <!--             <div class="cursor" ref="cursor">
              <div class="cursor--inner"></div>
            </div> -->

            <div class="col-6 u-py-30 order-3 order-lg-3">
              <hr />
              <h3 class="u-color-wh">Paddle Surf Peñíscola</h3>
            </div>
          </div>
        </div>
        <!-- end c-col-lg4 -->
        <!-- c-col-lg4 -->
        <div class="col-12 u-py-9 u-mb-2" data-animate="slide-right 0.5s linear forwards">
          <div class="row flex-sm-column flex-lg-row align-items-lg-end">
            <div class="col-lg-8 col-12 u-overflow--h">
              <img
                loading="lazy"
                class="u-img"
                src="@/assets/images/mascarillaplus-project-hz690-min.jpg"
                alt="Página de inicio de la tienda online Mascarilla Plus"
              />
            </div>
            <div class="col-lg-4 col-12 u-overflow--h">
              <p class="u-color-wh">
                Tienda online realizada con Wordpress y WooCommerce para la venta de material
                sanitario.
              </p>
              <p class="u-color-wh">E-COMMERCE | SEO</p>
            </div>
            <div class="col-6 offset-6 u-py-30">
              <hr />
              <h3 class="u-color-wh">Mascarilla Plus</h3>
            </div>
          </div>
        </div>
        <!-- end c-col-lg4 -->
        <!-- c-col-lg4 -->
        <div class="col-12 u-py-9 u-mb-2" data-animate="slide-left 0.5s linear forwards">
          <div class="row flex-sm-column flex-lg-row align-items-lg-end">
            <div class="col-lg-4 col-12 u-overflow--h order-2 order-lg-1">
              <p class="u-color-wh">Sitio web corporativo para un estudio de arquitectura.</p>
              <p class="u-color-wh">WEB DESIGN | SEO LOCAL</p>
            </div>
            <div class="col-lg-8 col-12 u-overflow--h order-1 order-lg-2">
              <img
                loading="lazy"
                class="u-img"
                src="@/assets/images/noventa-grados-studio.jpg"
                alt="Página de inicio del estudio de arquitectura 90 Grados"
              />
            </div>

            <div class="col-6 u-py-30 order-3 order-lg-3">
              <hr />
              <h3 class="u-color-wh">90 Grados Studio</h3>
            </div>
          </div>
        </div>
        <!-- end c-col-lg4 -->
      </div>
      <!-- End list of works -->
    </div>
  </section>
</template>

<script>
export default {
  // directives: {
  //   scroll: {
  //     inserted: function (el, binding) {
  //       let f = function (evt) {
  //         if (binding.value(evt, el)) {
  //           window.removeEventListener('scroll', f);
  //         }
  //       };
  //       window.addEventListener('scroll', f);
  //     },
  //   },
  // },
  // directives: {
  //   scroll: {
  //     inserted: function (el, binding) {
  //       const onScrollCallback = binding.value;
  //       window.addEventListener('scroll', () => onScrollCallback());
  //     },
  //   },
  // },
  data() {
    return {
      popup: false,
      mouseX: 0,
      mouseY: 0,
      xp: 0,
      yp: 0,
      mouse: { x: 300, y: 300 },
      pos: { x: 0, y: 0 },
      speed: 0.1, // between 0 and 1
      property1: 1,
    };
  },
  computed: {
    cursorCircle() {
      //return `transform: translateX(${this.pos.x}px) translateY(${this.pos.y}px) translateZ(0) translate3d(0, 0, 0);`;
      return `transform: translate3d(${this.pos.x}px, ${this.pos.y}px, 0);`;
    },
  },
  methods: {
    handleScroll: function (evt, el) {
      console.log(evt);

      if (window.scrollY > 1000) {
        el.setAttribute('style', 'opacity: 1; transform: translate3d(0, -10px, 0)');
      }
      return window.scrollY > 100;
    },
    mouseEnter(event) {
      console.log('mouseneter');
      console.log(event);
      this.popup = true;
      this.$el.addEventListener('mousemove', this.mouseMove, false);
    },
    mouseLeave(event) {
      console.log(event);
      this.popup = false;
      //this.$el.removeEventListener('mousemove', this.mouseMove());
    },

    updateCoordinates(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      setInterval(() => {
        this.pos.x += (this.mouse.x - this.pos.x) * this.speed;
        this.pos.y += (this.mouse.y - this.pos.y) * this.speed;
        /* this.$refs.cursor.style.left = this.xp + 'px';
        this.$refs.cursor.style.top = this.yp + 'px'; */
      }, 100);
    },

    mouseMove(e) {
      console.log(e.clientX, e.clientY);
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      /* console.log(this.$refs.circle); */

      setInterval(() => {
        this.xp = this.mouseX - 15;
        this.yp = this.mouseY - 15;
        this.$refs.cursor.style.left = this.xp + 'px';
        this.$refs.cursor.style.top = this.yp + 'px';
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
/* .u-bg-image-3 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url('@/assets/images/ux-store-lg.jpg');
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
} */
/* .circle {
  position: fixed;
  top: 0;
  left: 0;
  border: solid 1px #ccc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
  will-change: transform;
}
.prueba1:hover::after {
  display: block;
}

.prueba-btn {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.prueba1::after {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: red;
} */
.section {
  opacity: 1;
  transition: all 1s ease-in-out;
}
.projects {
  position: relative;
  /* min-height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: all 1s ease-in-out;

  h2 {
    margin-top: 100px;
    letter-spacing: 4px;
    font-size: 60px;
    z-index: 2;
    text-transform: uppercase;
  }
}
@media (min-width: 768px) {
  .projects {
    h2 {
      margin-top: 100px;
      letter-spacing: 4px;
      font-size: 100px;
    }
  }
}

.cursor {
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 200ms ease-out;
  will-change: transform;
}

.cursor .cursor--inner {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: solid 1px #1d213f;
}

.animate {
  opacity: 0;
}

.active {
  opacity: 1;
  // background-color: #262626;
}

/* Image Hover Overlay */
.lightbox-single {
  margin-bottom: 30px;
  color: #fff;

  h3 {
    margin-bottom: 0;
  }

  &:hover .over-lay {
    opacity: 1;
  }

  &:hover h3 {
    margin-bottom: 10px;
  }

  .image {
    display: block;
    position: relative;
    background-color: #f5f5f5;
    overflow: hidden;
  }

  .wallpaper {
    background-position: center center;
    background-size: cover;
  }

  .over-lay {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #00bfa6;
    text-align: center;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}
</style>
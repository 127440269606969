<template>
<div>
    <h2> Web Design</h2>
</div>
    
</template>

<script>
export default {
    
}
</script>

<style lang="scss">

</style>
<template>
  <section class="heroHome" id="inicio">
    <div class="u-bg-image-1"></div>
    <div class="col-10 col-md-12 c-card d-flex u-mx-auto u-glass u-py-15">
      <h1 class="c-card__title u-color-wh">
        <span class="u-text-highlight">Impulsa tu Éxito Digital:</span> Estrategias Innovadoras para
        Crecer en Línea
      </h1>
      <p class="u-mx-auto c-card__description u-color-wh">
        En nuestra agencia digital, nos apasiona la creación de soluciones digitales que marquen la
        diferencia. Desde desarrollo web hasta estrategias de marketing, nuestro enfoque es claro:
        potenciar tu presencia en línea y llevar tu negocio al siguiente nivel.
      </p>
    </div>
  </section>
  <servicios-cpn></servicios-cpn>
  <projects-cpn></projects-cpn>
  <prices-cpn></prices-cpn>
  <contact-component></contact-component>
</template>

<script>
import ServiciosCpn from '@/components/ServicesSection.vue';
import ProjectsCpn from '@/components/ProjectsSection.vue';
import PricesCpn from '@/components/PricesComponent.vue';
import ContactComponent from '@/components/ContactComponent.vue';
import io from '@/assets/js/io.js';
import menu from '@/assets/js/menu-toggle.js';

export default {
  components: {
    ServiciosCpn,
    ProjectsCpn,
    PricesCpn,
    ContactComponent,
  },
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  mounted() {
    io();
    menu();
    /* let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', './assets/js/io.js');
    recaptchaScript.setAttribute('type', 'text/javascript');
    document.head.appendChild(recaptchaScript); */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.heroHome {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  // h1::before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   height: 30%;
  //   // box-shadow: inset 0 -0.285em #00bfa6;
  //   // border-bottom: 0.3em solid rgba(100, 225, 55, 0.7);
  //   background-image: linear-gradient(to top, #00bfa6 60%, transparent 0);
  // }
}

.services {
  height: 100vh;
  background-color: red;
}
</style>
